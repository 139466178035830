import { createAndResetOnLogout } from 'stores/utils';
import { MarketType } from 'types/trading-types';

export type MarketStore = {
  markets: MarketType[];
  setMarkets: (markets: MarketType[]) => void;
};

export const useMarketStore = createAndResetOnLogout<MarketStore>((set) => ({
  markets: [],
  setMarkets: (markets: MarketType[]) => {
    set(() => ({
      markets,
    }));
  },
}));
