import { ValueItem } from 'components/features/ChipsSelector/types';
import {
  ActualOccupancyStatus,
  OccupancyStatusMap,
} from 'components/properties/attributes/PropertyOccupancyStatus/utils';
import { PROPERTY_STATUS_TO_TEXT } from 'components/properties/attributes/PropertyStatus/utils';
import { PROPERTY_TYPE_TO_TEXT } from 'components/properties/attributes/PropertyType/utils';
import {
  FilterTypeKey,
  OccupancyStatuses,
  PropertyStatuses,
  PropertyType,
} from 'consts/enums/property';
import { FilterType } from 'pages/feed/hooks/useFeedFilters';
import BuildingIcon from 'public/assets/icons/building.svg';
import BuildingBaseIcon from 'public/assets/icons/building_base.svg';
import DuplexIcon from 'public/assets/icons/duplex.svg';
import EvictionIcon from 'public/assets/icons/eviction.svg';
import HomeIcon from 'public/assets/icons/home_basic.svg';
import HomeNew from 'public/assets/icons/home_new.svg';
import HomeRoofIcon from 'public/assets/icons/home_roof.svg';
import LeaseExpiredIcon from 'public/assets/icons/lease_expired.svg';
import OccupiedIcon from 'public/assets/icons/occupied.svg';
import OrderBookIcon from 'public/assets/icons/order_book.svg';
import RenovationIcon from 'public/assets/icons/renovation.svg';
import SignRentIcon from 'public/assets/icons/sign_rent.svg';
import SparksIcon from 'public/assets/icons/sparks.svg';
import { MarketType } from 'types/trading-types';
import { stringOrFunction } from 'utils/helpers';

interface FilterPaneFilter {
  type: FilterType;
  display: string;
  values: { [key: string]: Partial<ValueItem> };
}

export const FILTER_PANE_FILTERS: FilterPaneFilter[] = [
  {
    type: FilterTypeKey.OFFERING_STATUSES,
    display: 'Offering',
    values: {
      [PropertyStatuses.PRIMARY]: {
        Icon: SparksIcon,
        display: PROPERTY_STATUS_TO_TEXT[PropertyStatuses.PRIMARY],
      },
      [PropertyStatuses.SECONDARY]: {
        Icon: OrderBookIcon,
        display: PROPERTY_STATUS_TO_TEXT[PropertyStatuses.SECONDARY],
      },
      [PropertyStatuses.PRE_SECONDARY]: {
        Icon: HomeNew,
        display: PROPERTY_STATUS_TO_TEXT[PropertyStatuses.PRE_SECONDARY],
      },
    },
  },
  {
    type: FilterTypeKey.OCCUPANCY_STATUSES,
    display: 'Occupancy Status',
    values: {
      [OccupancyStatuses.LEASED]: {
        Icon: OccupiedIcon,
        display: stringOrFunction(
          OccupancyStatusMap[ActualOccupancyStatus.LEASED].display,
          {}
        ),
      },
      [OccupancyStatuses.UNDER_TURNS_REHAB]: {
        Icon: RenovationIcon,
        display: stringOrFunction(
          OccupancyStatusMap[ActualOccupancyStatus.UNDER_RENOVATION].display,
          {}
        ),
      },
      [OccupancyStatuses.EVICTION]: {
        Icon: EvictionIcon,
        display: stringOrFunction(
          OccupancyStatusMap[ActualOccupancyStatus.EVICTION].display,
          {}
        ),
      },
      [OccupancyStatuses.HOLD_OVER]: {
        Icon: LeaseExpiredIcon,
        display: stringOrFunction(
          OccupancyStatusMap[ActualOccupancyStatus.EXPIRED].display,
          {}
        ),
      },
      [OccupancyStatuses.MARKETING]: {
        Icon: SignRentIcon,
        display: stringOrFunction(
          OccupancyStatusMap[ActualOccupancyStatus.FOR_RENT].display,
          {}
        ),
      },
    },
  },
  {
    type: FilterTypeKey.PROPERTY_TYPES,
    display: 'Property Type',
    values: {
      [PropertyType.SFR]: {
        Icon: HomeIcon,
        display: PROPERTY_TYPE_TO_TEXT[PropertyType.SFR],
      },
      [PropertyType.DUPLEX]: {
        Icon: DuplexIcon,
      },
      [PropertyType.MFR]: {
        Icon: BuildingBaseIcon,
        display: PROPERTY_TYPE_TO_TEXT[PropertyType.MFR],
      },
      [PropertyType.TH]: {
        Icon: HomeRoofIcon,
        display: PROPERTY_TYPE_TO_TEXT[PropertyType.TH],
      },
    },
  },
];

export const makeDynamicMarketFilter = (
  markets: MarketType[]
): FilterPaneFilter => {
  const values: { [key: string]: Partial<ValueItem> } = {};
  markets.forEach((market) => {
    values[market.name] = {
      Icon: BuildingIcon,
      display: market.name,
    };
  });

  return {
    type: FilterTypeKey.MARKETS,
    display: 'Market',
    values,
  };
};
