import { ValueItem } from 'components/features/ChipsSelector/types';

type ObjectToChipsSelectorValuesProps = (obj: {
  [key: string]: Partial<ValueItem>;
}) => ValueItem[];

export const objectToChipSelectorValues: ObjectToChipsSelectorValuesProps = (
  obj
) => {
  return Object.entries(obj).map(([key, item]) => ({
    ...item,
    value: item.value || key,
    display: item.display || key,
  }));
};
