import { FC } from 'react';
import { Popup as OriginalPopup } from 'react-mapbox-gl';

interface Props {
  children?: React.ReactNode;
  className?: string;
  coordinates: number[];
  offset: number[];
}

const Popup: React.FC<Props> = (props) => {
  return <OriginalPopup {...props} />;
};

export default Popup;
