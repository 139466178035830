import classNames from 'classnames';
import Money from 'components/generics/texts/Money';
import Typography from 'components/generics/texts/Typography';
import { PROPERTY_TYPE_TO_TEXT } from 'components/properties/attributes/PropertyType/utils';
import { PropertyType as PropertyTypeEnum } from 'consts/enums/property';
import useStockPrice from 'hooks/requests/properties/useStockPrice';
import { HTMLAttributes } from 'react';
import { PhotoType, PropertyAddress, PropertyType } from 'types/property-types';
import classes from './index.module.scss';
import { getRent } from 'utils/property_utils';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'property'> {
  property: Partial<PropertyType> & {
    ticker: string;
    address: PropertyAddress;
    beds?: number;
    baths?: number;
    monthlyRent?: number;
    sqf?: number;
    coordinates: {
      longitude: number;
      latitude: number;
    };
    photos: PhotoType[];
    propertyType: PropertyTypeEnum;
  };
  price?: number;
  isResident?: boolean;
}

const MapDetailsPropertyCard = ({
  property,
  className,
  price,
  isResident,
}: Props) => {
  const propertyTypeText =
    PROPERTY_TYPE_TO_TEXT[property?.propertyType] || property?.propertyType;
  const { [property?.ticker]: sharePrice } = useStockPrice(
    property?.ticker,
    false
  );
  const monthlyRent = getRent(property);

  return (
    <div className={classNames(classes.container, className)}>
      {!isResident ? (
        <>
          <div className={classes.topSection}>
            <Typography className={classNames(classes.title, classes.ellipsis)}>
              {property?.address.houseNumber} {property?.address.street}
            </Typography>
            <Money
              withDecimal={true}
              className={classNames(classes.title, classes.stockPrice)}
              value={price || sharePrice}
            />
          </div>
          <div className={classes.bottomSection}>
            <Typography className={classes.subtitle}>
              {property?.address.city.name}, {property?.address.state.code} ∙{' '}
              {propertyTypeText}
            </Typography>
          </div>
        </>
      ) : (
        <div className={classes.residentsContainer}>
          <div className={classes.residentsLeftSection}>
            <Money
              suffix={'/mo'}
              className={classNames(classes.residentsTitle, classes.stockPrice)}
              value={monthlyRent}
            />
            <Typography
              className={classNames(
                classes.residentsSubtitle,
                classes.residentsElipses
              )}
            >
              {property.address.houseNumber} {property.address.street}
            </Typography>
            <Typography className={classNames(classes.residentsSubtitle)}>
              {property.address.city.name}, {property.address.state.code}
            </Typography>
          </div>
          <div className={classes.residentsRightSection}>
            <Typography className={classes.residentsSubtitle}>
              {property.beds} bds {' ∙ '} {property.baths} ba
            </Typography>
            <Typography className={classes.residentsSubtitle}>
              {property.sqf} sqft
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default MapDetailsPropertyCard;
