import BeatLoader from 'components/animations/Loaders/BeatLoader';
import CoalesceContainer from 'components/containers/CoalesceContainer';
import InfiniteScrollContainer from 'components/containers/InfiniteScrollContainer';
import classes from './index.module.scss';
import React from 'react';
import FeedPropertyCardLoader from 'components/properties/PropertyCard/FeedPropertyCard/components/FeedPropertyCardLoader';
import { FeedPropertyType } from 'pages/feed/[market]/hooks/useMarketItemsFetcher';
import FeedEmptyState from 'pages/feed/components/FeedEmptyState';
import FeedItem from 'pages/feed/components/FeedItem';

export type PropertiesFetcher<T> = (props: T) => {
  loading: boolean;
  data: FeedPropertyType[];
  fetchNextPage: () => void;
  hasMore: boolean;
  initialized: boolean;
};

interface Props<T> {
  preloadedProperties: FeedPropertyType[];
  onScroll?: (e: MouseEvent) => void;
  usePreloadedPropertiesCondition?: boolean;
  startedWorkingCondition?: boolean;
  fetcherLoading: boolean;
  fetcherInitialized: boolean;
  fetchNextPage: () => void;
  hasMore: boolean;
}

export const RESIDENT_FILTER = {
  occupancyStatuses: ['marketing', 'under_turns_rehab'],
};

const PropertiesGrid = <T,>({
  preloadedProperties,
  onScroll,
  fetchNextPage,
  fetcherInitialized,
  hasMore,
  fetcherLoading,
  usePreloadedPropertiesCondition = true,
  startedWorkingCondition = true,
}: Props<T>) => {
  // Logic for what to show
  const startedWorking = fetcherInitialized && startedWorkingCondition;
  const dataEmpty = preloadedProperties.length === 0;
  const usePreloadedProperties =
    startedWorking &&
    fetcherLoading &&
    dataEmpty &&
    usePreloadedPropertiesCondition;
  const loading = fetcherLoading;
  const firstTimeLoading = !startedWorking || (dataEmpty && loading);
  const finalData = preloadedProperties;

  return (
    <InfiniteScrollContainer
      outerDivClassName={classes.outerDiv}
      next={fetchNextPage}
      hasMore={hasMore && !usePreloadedProperties}
      dataLength={preloadedProperties.length}
      loader={loading && <BeatLoader className={classes.loadMore} />}
      onScroll={onScroll}
    >
      <div className={classes.grid}>
        <CoalesceContainer>
          {firstTimeLoading &&
            [...Array(8)].map((e, i) => <FeedPropertyCardLoader key={i} />)}
          {!finalData?.length && <FeedEmptyState />}
          {finalData?.map((item, index) => (
            <FeedItem
              isResident
              className={classes.feedItem}
              item={item}
              key={index.toString()}
            />
          ))}
        </CoalesceContainer>
      </div>
    </InfiniteScrollContainer>
  );
};

export default PropertiesGrid;
