import classNames from 'classnames';
import Typography from 'components/generics/texts/Typography';
import { HTMLAttributes } from 'react';
import { Marker } from 'react-mapbox-gl';
import classes from './index.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  coordinates: number[];
  pointCount: number;
}

const MapCluster = ({ className, coordinates, pointCount }: Props) => {
  return (
    <Marker
      className={classNames(classes.circle, className)}
      coordinates={coordinates}
    >
      <Typography className={classes.text}>{pointCount}</Typography>
    </Marker>
  );
};

export default MapCluster;
