export enum PHOTO_SIZE {
  COMPRESSED = 'compressed',
  THUMBNAIL = 'thumbnail',
  MEDIUM = 'medium',
  TINY = 'tiny',
  LARGE = 'large',
}

const replaceUri = (uri: string, sizeName: PHOTO_SIZE) => {
  if (
    !/^https:\/\/(www\.)?(landa-managed-properties-public\.s3|properties-static).*$/.test(
      uri
    )
  ) {
    return uri;
  }
  const parts = uri.split('.');
  const lastPart = parts.pop();

  if (!parts[parts.length - 1] || !lastPart) return null;

  parts[parts.length - 1] = parts[parts.length - 1].replace(
    /_(compressed|thumbnail|medium|tiny|large)$/,
    ''
  );

  return parts.join('.') + '_' + sizeName + '.' + lastPart;
};

export const sizeSpecificImages = (uriList: string[], sizeName: PHOTO_SIZE) => {
  return uriList.map((uri) => replaceUri(uri, sizeName));
};
