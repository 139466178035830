import classNames from 'classnames';
import AllChip from 'components/features/ChipsSelector/components/AllChip';
import classes from 'components/features/ChipsSelector/index.module.scss';
import {
  ChipsSelectorProps,
  ValueItem,
  ValueType,
} from 'components/features/ChipsSelector/types';
import Chip from 'components/generics/buttons/Chip';
import { arrayify } from 'utils/helpers';

const ChipsSelector = ({
  values,
  className,
  selected,
  onClick,
  onAllChipClick,
  selectedClassName,
  unselectedClassName,
  chipClassName,
  withAllChip = false,
  ...props
}: ChipsSelectorProps) => {
  const selectedArray = arrayify<ValueType>(selected);

  return (
    <div
      className={classNames(classes.chipsSelector, className)}
      {...props}
    >
      {withAllChip && (
        <AllChip
          onClick={onAllChipClick}
          selected={selectedArray}
          unselectedClassName={unselectedClassName}
        />
      )}
      {values.map((item: ValueItem) => {
        const { value, display, Icon } = item;
        const onChipClick = () => onClick(item.value);

        return (
          <Chip
            className={chipClassName}
            Icon={Icon}
            onClick={onChipClick}
            key={value || display}
            selectedClassName={selectedClassName}
            unselectedClassName={unselectedClassName}
            isSelected={selectedArray.indexOf(value) > -1}
          >
            {display}
          </Chip>
        );
      })}
    </div>
  );
};

export default ChipsSelector;
