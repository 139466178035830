import Button from 'components/generics/buttons/Button';
import Typography from 'components/generics/texts/Typography';
import { FilterTypeKey } from 'consts/enums/property';
import useFeedFilters, { FilterTypes } from 'pages/feed/hooks/useFeedFilters';
import EscIcon from 'public/assets/icons/esc.svg';
import classes from './index.module.scss';

const EXCLUDE_FILTERS = [FilterTypeKey.CATEGORIES];

interface Props {
  filters: FilterTypes;
  onDismiss?: () => void;
  resetFilters: () => void;
}

const FilterDrawerHeader = ({ onDismiss, filters, resetFilters }: Props) => {
  const { getFiltersCount } = useFeedFilters();
  const filtersCount = getFiltersCount(EXCLUDE_FILTERS, filters);

  return (
    <div className={classes.container}>
      <div>
        <EscIcon
          className={classes.escIcon}
          onClick={onDismiss}
        />
      </div>
      <Typography className={classes.label}>Filter</Typography>
      <div>
        <Button
          disabled={filtersCount === 0}
          className={classes.resetButton}
          onClick={resetFilters}
          variant="text"
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

export default FilterDrawerHeader;
