import { ValueType } from 'components/features/ChipsSelector/types';
import Chip from 'components/generics/buttons/Chip';

interface AllChip {
  unselectedClassName?: string;
  selected: ValueType[];
  onClick?: () => void;
}

const AllChip = ({ selected, onClick, unselectedClassName }: AllChip) => {
  const isAnySelected = selected.length > 0;
  return (
    <Chip
      isSelected={!isAnySelected}
      unselectedClassName={unselectedClassName}
      onClick={isAnySelected ? onClick : undefined}
    >
      All
    </Chip>
  );
};

export default AllChip;
