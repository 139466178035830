import classNames from 'classnames';
import { AnimatePresence, motion, MotionProps } from 'framer-motion';
import { PropsWithChildren } from 'react';
import classes from './index.module.scss';
import { motionContainerProps, motionOverlayProps } from './utils';

interface Props extends PropsWithChildren<MotionProps> {
  show: boolean;
  className?: string;
  onDismiss?: () => void;
}

const DrawerContainer = ({ show, className, onDismiss, ...props }: Props) => {
  return (
    <AnimatePresence>
      {show && (
        <>
          <motion.div
            {...motionOverlayProps}
            onClick={onDismiss}
            className={classes.overlay}
          />
          <motion.div
            className={classNames(classes.container, className)}
            {...motionContainerProps}
            {...props}
          />
        </>
      )}
    </AnimatePresence>
  );
};

export default DrawerContainer;
