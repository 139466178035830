import Button from 'components/generics/buttons/Button';
import useFeedFilters, { FilterTypes } from 'pages/feed/hooks/useFeedFilters';
import useFeedItemsCount from '../../../PropertiesContainer/hooks/useFeedItemsCount';
import { RESIDENT_FILTER } from 'pages/residents/feed/components/PropertiesContainer/components/PropertiesGrid';
import { useMemo } from 'react';

interface Props {
  isResident?: boolean;
  filters: FilterTypes;
  onDismiss?: () => void;
}

const getLabel = (loading: boolean, count: number) => {
  if (loading) return 'Loading...';
  if (count === 0) return 'No Properties';
  return `Show ${count} Properties`;
};

const FilterDrawerApplyButton = ({ isResident, filters, onDismiss }: Props) => {
  const filtersState = useMemo(() => {
    return isResident ? { ...filters, ...RESIDENT_FILTER } : filters;
  }, [isResident, filters]);

  const { setFilters } = useFeedFilters();
  const { count, loading } = useFeedItemsCount(filtersState);

  const onClick = async () => {
    await setFilters(filters);
    onDismiss?.();
  };

  return (
    <Button
      onClick={onClick}
      disabled={count === 0}
    >
      {getLabel(loading, count)}
    </Button>
  );
};

export default FilterDrawerApplyButton;
