import React, { PropsWithChildren, useEffect, useState } from 'react';

interface Props extends PropsWithChildren {
  children?: React.ReactNode;
  deps?: any;
  unmountOn?: boolean;
}

const ComponentMounter = ({ children, deps, unmountOn }: Props) => {
  const [wait, setWait] = useState(false);

  useEffect(() => {
    setWait(true);
    setTimeout(() => setWait(false), 20);
  }, [deps]);

  return <>{wait || unmountOn ? null : children}</>;
};

export default ComponentMounter;
