import MapDetailsPropertyCard from 'components/properties/PropertyCard/MapPropertyCard/MapDetailsPropertyCard';
import MapImagePropertyCard from 'components/properties/PropertyCard/MapPropertyCard/MapImagePropertyCard';
import { PropertyType as PropertyTypeEnum } from 'consts/enums/property';
import Link from 'next/link';
import { HTMLAttributes } from 'react';
import { PhotoType, PropertyAddress, PropertyType } from 'types/property-types';
import {
  encodeTicker,
  propertyAddressPathGenerator,
} from 'utils/property_utils';
import classes from './index.module.scss';
import { useRouter } from 'next/router';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'property'> {
  property: Partial<PropertyType> & {
    ticker: string;
    address: PropertyAddress;
    coordinates: {
      longitude: number;
      latitude: number;
    };
    photos: PhotoType[];
    propertyType: PropertyTypeEnum;
  };
  price?: number;
  isResident?: boolean;
}

const MapPropertyCard = ({ property, price, isResident }: Props) => {
  const path = propertyAddressPathGenerator(property.address);
  const route = useRouter();
  return (
    <Link
      href={
        route.pathname.includes('/residents')
          ? `/residents/property/${path}/${encodeTicker(property.ticker)}`
          : `/property/${path}/${encodeTicker(property.ticker)}`
      }
    >
      <div
        className={isResident ? classes.residentsContainer : classes.container}
      >
        <MapImagePropertyCard
          property={property}
          isResident={isResident}
        />
        <MapDetailsPropertyCard
          property={property}
          price={price}
          isResident={isResident}
        />
      </div>
    </Link>
  );
};

export default MapPropertyCard;
