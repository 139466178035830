import useShadowScrolledDown from 'hooks/logics/useShadowScrolledDown';
import { createContext, ReactNode, UIEvent, useContext, useState } from 'react';

export type FeedType = {
  showFilterPane: boolean;
  dismissFiltersPane: () => void;
  openFiltersPane: () => void;
  handleScroll: (e: MouseEvent | UIEvent) => void;
  shadowClassName: string;
};

const FeedContext = createContext<FeedType>({} as FeedType);

export const FeedProvider = ({ children }: { children: ReactNode }) => {
  const [showFilterPane, setShowFilterPane] = useState(false);
  const dismissFiltersPane = () => setShowFilterPane(false);
  const openFiltersPane = () => setShowFilterPane(true);
  const { handleScroll, shadowClassName } = useShadowScrolledDown();

  return (
    <FeedContext.Provider
      value={{
        showFilterPane,
        dismissFiltersPane,
        openFiltersPane,
        handleScroll,
        shadowClassName,
      }}
    >
      {children}
    </FeedContext.Provider>
  );
};

const useFeed = () => {
  return useContext(FeedContext);
};

export default useFeed;
