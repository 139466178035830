import { gql } from '@apollo/client';

export const FEED_ITEMS_COUNT = gql`
  query feedItemsCount(
    $categories: [String]
    $offeringStatuses: [String]
    $propertyTypes: [String]
    $occupancyStatuses: [String]
    $markets: [String]
  ) {
    homeFeedFilter(
      categories: $categories
      offeringStatuses: $offeringStatuses
      propertyTypes: $propertyTypes
      occupancyStatuses: $occupancyStatuses
      markets: $markets
    ) {
      totalCount
    }
  }
`;
