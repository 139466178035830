import classNames from 'classnames';
import classes from 'hooks/logics/useShadowScrolledDown/index.module.scss';
import { UIEvent, useEffect, useState } from 'react';

const useShadowScrolledDown = (useWindow = false) => {
  const [shadow, setShadow] = useState(false);

  const handleScroll = (e: MouseEvent | UIEvent) => {
    const el = (e?.currentTarget || e?.target) as HTMLElement;
    let scrollTop = el?.scrollTop;
    if (!scrollTop) {
      const newEl = el as any;
      scrollTop = newEl?.documentElement?.scrollTop;
    }
    const value = scrollTop > 0;
    if (shadow != value) {
      setShadow(value);
    }
  };

  useEffect(() => {
    if (useWindow) {
      window.addEventListener(
        'scroll',
        function () {
          const event = {
            currentTarget: {
              scrollTop: window.scrollY,
            },
          };
          handleScroll(event as unknown as MouseEvent | UIEvent);
        },
        false
      );
    }
  }, [useWindow]);

  return {
    handleScroll,
    shadowClassName: classNames(classes.shadow, !shadow && classes.hide),
  };
};

export default useShadowScrolledDown;
