import { FEED_ITEMS_COUNT } from 'graphql/feed/queries/feedItemsCount';
import { GQL_FETCHERS } from 'hooks/api/SWR/graphql/gqlFetchers';
import { useGraphqlMutation } from 'hooks/api/SWR/graphql/hooks/useGraphqlMutation';
import debounce from 'lodash/debounce';
import { FilterTypes } from 'pages/feed/hooks/useFeedFilters';
import { useEffect, useMemo } from 'react';

const useFeedItemsCount = (filters: FilterTypes) => {
  const { data, isMutating, trigger } = useGraphqlMutation(
    GQL_FETCHERS.REAL_ESTATE_GQL_FETCHER,
    FEED_ITEMS_COUNT,
    filters
  );

  const debounceTrigger = useMemo(() => debounce(trigger, 50), []);
  useEffect(() => {
    debounceTrigger(filters);
  }, [debounceTrigger, filters]);

  return {
    count: data?.homeFeedFilter?.totalCount,
    loading: isMutating,
  };
};

export default useFeedItemsCount;
